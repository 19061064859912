import React from 'react';
import './SleepHealthReportBanner.scss';
import Chevron from '../../assets/icons/chevron_right_white.svg';

export default function SleepHealthReportBanner() {
  return (
    <a
      className="sleep-health-report-banner"
      href="../../dreem_health_sleep_health_report_2025.pdf"
      onClick={() => {
        if (window.posthog) window.posthog.capture('sleep_health_report_banner_clicked');
      }}
      target="_blank"
    >
      <p>
        <span className="sleep-icon sleep-icon--desktop">😴</span>
        <span>What’s the State of Sleep Health in 2025?</span>
        <span className="sleep-icon sleep-icon--mobile">😴</span>
      </p>
      <p className="separator">–</p>
      <p className="access-free-report">
        <span>
          Access
          {' '}
          <span className="highlighted">FREE report</span>
          {' '}
          while available
        </span>
        <Chevron className="chevron" />
      </p>
    </a>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Phoning } from '@hello-sunrise/phoning';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import PhoningBanner from '../components/phoningBanner/PhoningBanner';
import QueryLoader from '../components/queryLoader/QueryLoader';
import useEnrollmentUrl from '../hooks/useEnrollmentUrl';
import Preview from '../images/preview.png';
import SleepHealthReportBanner from '../components/sleepHealthReportBanner/SleepHealthReportBanner';

export default function Layout({ children, pageContext }) {
  const { pageName, headerType } = pageContext;

  const displayPhoning = process.env.ENABLE_PHONING === 'true' && headerType === 'default';
  const { enrollmentUrl } = useEnrollmentUrl();
  const [displaySleepHealthReportBanner] = useState(() => {
    if (typeof window === 'undefined') return false;
    return new URLSearchParams(window.location.search).get('search') === null;
  });

  return (
    <>
      <Helmet>
        <link rel="icon" href="../../img/favicon.svg" />
        <title>Digital sleep clinic | Dreem Health</title>
        <meta property="og:title" content="Digital sleep clinic | Dreem Health" />
        <meta
          property="og:description"
          content="Care on your terms and schedule, with diagnosis & treatment options for your sleep condition. All from the comfort of your home."
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content="https://dreem.com/en/" />
        <meta property="og:site_name" content="Digital sleep clinic | Dreem Health" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
        />
      </Helmet>
      <QueryLoader />
      <main className={pageName}>
        {displayPhoning && (
          <>
            <PhoningBanner />
            <div className="phoning-container">
              <Phoning withBorder clientOnly envName={process.env.PHONING_ENV_NAME} />
            </div>
          </>
        )}

        {displaySleepHealthReportBanner && (
          <>
            <SleepHealthReportBanner />
            <div style={{ marginTop: '60px' }} />
          </>
        )}

        {headerType !== 'none' && (
          <Header
            enrollmentUrl={enrollmentUrl}
            displayPhoning={displayPhoning}
            displaySleepHealthReportBanner={displaySleepHealthReportBanner}
            light={headerType === 'light'}
          />
        )}

        {children}

        {headerType === 'default' && <Footer />}
      </main>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  pageContext: PropTypes.shape({
    pageName: PropTypes.string.isRequired,
    headerType: PropTypes.string.isRequired,
  }).isRequired,
};

import React from 'react';
import './Footer.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, StaticQuery } from 'gatsby';
import Check from '../../assets/icons/check.svg';

export default function Footer() {
  function copyToClipboard() {
    const popupCopied = document.querySelector('footer .contact-container .popup-copied');
    navigator.clipboard.writeText('hello@dreemhealth.com');
    popupCopied.classList.add('active');
    setTimeout(() => {
      popupCopied.classList.remove('active');
    }, 3000);
    if (window.posthog) window.posthog.capture('contact_footer_clicked');
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          directus {
            website_footer {
              legal_key {
                key
                title
              }
            }
          }
        } 
      `}
      render={(data) => (
        <footer>
          <div className="background">
            <div className="wave" />
          </div>

          <h2 className="desktop-only">
            Change your sleep,
            change your life.
          </h2>

          <h2 className="mobile-only">
            Get the sleep you need, live the life you want.
          </h2>

          <div className="contact-container">
            <p className="overline salmon">contact us</p>
            <h5>Fax: 628-216-8120</h5>
            <a href="tel:+6507614056">
              <h5>
                Phone: (650) 761-4056
              </h5>
            </a>

            <button
              type="button"
              onClick={() => copyToClipboard()}
            >
              <h5>
                hello@dreemhealth.com
              </h5>
            </button>
            <div className="popup-copied">
              <Check />
              <p>Mail copied to clipboard!</p>
            </div>
          </div>

          <div className="bottom-container">
            <div className="certifications-container">
              <div className="images-container">
                <StaticImage
                  src="../../images/footer/certification_legiscript.png"
                  alt="legiscript"
                />
                <StaticImage
                  src="../../images/footer/certification_achc.png"
                  alt="achc"
                />
              </div>

              <p>
                Our accreditations reflect our commitment to the highest standards of quality and
                patient care.
              </p>
            </div>

            <div className="footer">
              <StaticImage class="logo" src="../../images/logo_new.png" alt="" />

              <div className="navigation">
                <Link to="/blog">Blog</Link>
                {data.directus.website_footer.map((page) => (
                  <Link
                    key={page.legal_key.title}
                    to={`/legal/${page.legal_key.key}`}
                  >
                    {page.legal_key.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </footer>
      )}
    />
  );
}
